import React, { useState, useEffect } from "react";
import "./app.css";
import Logo from "./components/photo/icamp-re.png";
import { motion } from "framer-motion";
import EmailVerification from "./components/EmailVerification";
import PersonalDetails from "./components/PersonalDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Progress from "./components/Progress";
import Popup from "./components/Popup";
import { useGlobalProgressContext } from "./components/ProgressContext";
import logo from "./logo.png";
import { MdSecurity } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";
// import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import Footer from "./components/Footer";
import PaymentInfo from "./components/PaymentInfo";
function App() {
  const { emailDone, detailsDone, paymentDone, paid, setPaid } =
    useGlobalProgressContext();
  useEffect(() => {
    const local_email = localStorage.getItem("icamp_email_step_1");
    if (local_email) {
      axios
        .get(`https://api.ecell.org.in/api/checkmail/:${local_email}`)
        .then((res) => {
          if (res.data.user.payments.isPaid == true) {
            setPaid(true);
          }
        });
    }
  }, []);

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-[#fefeff] to-[#39ACE3]/90 overflow-hidden">
      <ToastContainer />
      <div className="relative flex flex-col items-center justify-center h-screen gap-0">
        <div
          className="absolute top-0 flex justify-center w-screen left-5"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src={Logo} className="absolute top-0 left-0 h-40" />
        </div>
        {/* Top */}
        <div className="w-full max-w-[500px] mainDivShadow rounded-3xl">
          <div className="max-w-[884px] w-full flex flex-col items-center  mb-0 rounded-t-3xl md:px-32  bg-[#39ACE3] text-[#002232] h-44 pb-0 pt-0  px-5 ">
            <Progress />
            <p className="pt-0 my-10 text-2xl font-bold text-center">
              You are just one step away from launching your career.
            </p>
          </div>

          {/* bottom */}

          <div className="max-w-[884px] w-full flex flex-col items-center  pb-0  md:px-40  bg-white text-[#002232] px-5  mt-0">
            <div className="w-full mb-8"></div>
            {/* <Popup />  */}
            {/* <ToastContainer /> */}
            <div className="w-full h-full mt-2 ">
              {/* ~~~~ Components come here ~~~~ */}
              {paid ? (
                <Popup />
              ) : paymentDone ? (
                <PaymentInfo />
              ) : !emailDone ? (
                <EmailVerification />
              ) : (
                <PersonalDetails />
              )}
            </div>
          </div>

          <Footer />
        </div>
        {/* Footer */}
        <motion.p
          className="pt-0 my-10 text-2xl font-bold text-center text-white"
          initial={{ y: 900 }}
          animate={{ y: 0 }}
          whileHover={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          © Copyright 2022. All Rights Reserved.KIIT E-Cell
        </motion.p>
      </div>
    </div>
  );
}

export default App;
