import React from "react";
import "../assets/css/popup.css";

export default function Popup() {
  return (
    <div>
      <div className="bg-modal1">
        <div className="modal-content1">
          <div className="container">
            <div className="row">
              <div className="mx-auto mt-5 col-md-6">
                <div className="payment">
                  <div className="payment_header">
                    <div className="check">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="content">
                    <h1>Payment Successful!!</h1>
                    <p>
                      You have been successfully registered for Internship Camp.
                    </p>
                    <br />
                    <a href="https://icamp.ecell.org.in/?page_id=1223">
                      Go to Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
