import Axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useGlobalProgressContext } from "./ProgressContext";
import { AiFillEye } from "react-icons/ai";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { AiFillTag } from "react-icons/ai";

const PersonalDetails = () => {
  const { setPaymentDone, setDetailsDone, coupon, setCoupon } =
    useGlobalProgressContext();
  const url = "https://api.ecell.org.in/api/signup";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState();
  const [password, setPassword] = useState();
  const [Branch, setBranch] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePhone = (phone) => {
    return String(phone)
      .toLowerCase()
      .match(
        /^((\+|0{0,2})([0-9]){1,3})?[-.●\s]?\(?([0-9]{2,3})\)?[-.●\s]?([0-9]{3})[-.●\s]?([0-9]{4})$/
      );
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (
      coupon === "" ||
      coupon === "CAREER20KE" ||
      coupon === "SPECIAL10" ||
      coupon === "FRESHERS15"||
      coupon === "PRONOOB15" ||
      coupon === "KASH20" ||
      coupon === "AEWS20" ||
      coupon === "KEYE20" ||
      coupon === "MSAC20" ||
      coupon === "TREAT15"
    ) {
      if (validateEmail(email)) {
        if (validatePhone(contact)) {
          if (isPatch) {
            axios
              .patch("https://api.ecell.org.in/api/update", {
                name: name,
                email: email,
                password: password,
                branch: Branch,
                no: contact,
                combo_code: coupon,
              })
              .then((res) => {
                console.log(res.data);
              });
          } else {
            axios
              .post(url, {
                name: name,
                email: email,
                password: password,
                branch: Branch,
                no: contact,
                combo_code: coupon,
              })
              .then((res) => {
                console.log(res.data);
              });
          }
          if (
            coupon === "CAREER20KE" ||
            coupon === "SPECIAL10" ||
            coupon === "FRESHERS15" ||
            coupon === "PRONOOB15" ||
            coupon === "KASH20" ||
            coupon === "AEWS20" ||
            coupon === "KEYE20" ||
            coupon === "MSAC20" ||
            coupon === "TREAT15"
          ) {
            toast.success("You have been added to the waiting list.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (coupon === "") {
            toast.success("You have been added to the waiting list", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // setPaymentDone(true);
          setDetailsDone(true);
        } else {
          toast.error("Not a valid Phone No", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Not a valid Email Id", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Coupon code not valid.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    const local_email = localStorage.getItem("icamp_email_step_1");
    Axios.get(`https://api.ecell.org.in/api/checkmail/:${local_email}`).then(
      (res) => {
        if (res.data.status === "User already exist") {
          setName(res.data.user.name);
          setBranch(res.data.user.branch);
          setContact(res.data.user.no);

          if (res.data.user.payments.isPaid === false) {
            setIsPatch(true);
          }
        }
      }
    );
    if (local_email) {
      setEmail(local_email);
    }
  }, []);
  return (
    <div>
      <form
        action=""
        onSubmit={submitForm}
        className="flex flex-col items-center w-full gap-10 pt-0"
      >
        <h1 className="mb-0 text-3xl font-bold">
          Enter your{" "}
          <span className="underline underline-offset-2 decoration-4 decoration-[#39ACE3]">
            {" "}
            Details{" "}
          </span>
        </h1>
        <div className="flex flex-col items-end gap-4">
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="name">
              Full Name
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
              type="text"
              name="name"
              id="name"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="email">
              KIIT Email
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0 ml-1.5"
              type="text"
              name="email"
              id="email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="contact">
              Phone No
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
              type="tel"
              name="contact"
              id="contact"
              autoComplete="off"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="relative flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="contact">
              New Password
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
              type={`${showPassword ? "text" : "password"}`}
              name="password"
              id="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute right-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiFillEye className="text-2xl" />
              ) : (
                <AiFillEyeInvisible className="text-2xl" />
              )}
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="contact">
              Branch
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
              type="text"
              name="branch"
              id="branch"
              autoComplete="off"
              value={Branch}
              onChange={(e) => setBranch(e.target.value)}
            />
          </div>
          {/* <div className="flex flex-wrap items-center justify-center gap-4">
            <label
              className="flex items-center gap-3 font-semibold "
              htmlFor="contact"
            >
              Coupon Code <AiFillTag />
            </label>
            <input
              className="px-2 py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
              type="text"
              name="couponCode"
              id="couponCode"
              autoComplete="off"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              placeholder="Optional"
            />
          </div> */}
        </div>

        <button
          className="px-4 py-2 mb-4 ml-20 transition duration-300 bg-[#39ACE3] text-white font-semibold rounded-md shadow-md focus:outline-none hover: hover:scale-125"
          type="submit"
        >
          {" "}
          Submit
        </button>
      </form>
    </div>
  );
};

export default PersonalDetails;
