import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ProgressProvider } from "./components/ProgressContext";

ReactDOM.render(
  <ProgressProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ProgressProvider>,
  document.getElementById("root")
);
