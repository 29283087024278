import React from "react";
import "../assets/css/progress.css";
import { useGlobalProgressContext } from "./ProgressContext";
export default function Progress() {
  const { emailDone, detailsDone, paymentDone } = useGlobalProgressContext();

  return (
    <>
      <ol
        className="progtrckr mb-12 flex w-full justify-center text-white font-[500] "
        data-progtrckr-steps="5"
      >
        <li
          className={`progtrckr-${
            emailDone ? "done" : "todo"
          } whitespace-nowrap w-full`}
        >
          Email Registered
        </li>
        <li
          className={`progtrckr-${
            detailsDone ? "done" : "todo"
          } whitespace-nowrap w-full`}
        >
          Details
        </li>
        <li
          className={`progtrckr-${
            paymentDone ? "done" : "todo"
          } whitespace-nowrap w-full`}
        >
          Payment
        </li>
      </ol>
    </>
  );
}
