import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { useGlobalProgressContext } from "./ProgressContext";
const EmailVerification = () => {
  const { setEmailDone, setPaid } = useGlobalProgressContext();
  const [email, setEmail] = useState("");
  const [allLogin, allNewLogin] = useState([]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (email && validateEmail(email)) {
      Axios.get(`https://api.ecell.org.in/api/checkmail/:${email}`).then(
        (res) => {
          if (res.data.status === "I-Camp is only for KIIT Students") {
            toast.error(
              "I-Camp is only for KIIT Students, Please use a KIIT email id.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            if (res.data?.user?.payments?.isPaid == true) {
              setPaid(true);
            } else {
              if (res.data.status === "User already exist") {
                toast.success("You can proceed to update your details", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  setEmailDone(true);
                  localStorage.setItem("icamp_email_step_1", email);
                }, 3000);
                console.log(res);
              } else if (res.data.status === "User doesnot exist") {
                toast.success("Welcome to I-Camp 2022", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  setEmailDone(true);
                  localStorage.setItem("icamp_email_step_1", email);
                }, 3000);
                console.log(res);
              }
            }
          }
        }
      );
    } else {
      toast.error("Please check your email", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    //const newLogin ={email:email}
    //allNewLogin([... allLogin,newLogin] );
  };
  return (
    <div>
      <form
        action=""
        onSubmit={submitForm}
        className="flex flex-col items-center w-full gap-10 rounded-3xl "
      >
        <h1 className="mb-10 text-3xl font-bold">
          Verify your{" "}
          <span className="underline underline-offset-2 decoration-4 decoration-[#39ACE3]">
            {" "}
            KIIT Email{" "}
          </span>
        </h1>

        <div className="flex flex-wrap items-center justify-center gap-6">
          <label className="font-semibold " htmlFor="email">
            KIIT Email
          </label>
          <input
            className="px-2 py-1 font-semibold  bg-[#E8F0FE]  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0"
            type="text"
            name="email"
            id="email"
            placeholder="Enter your KIIT mail id"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button
          className="px-4 py-2 mb-4 ml-20 transition duration-300 bg-[#39ACE3] text-white font-semibold rounded-md shadow-md focus:outline-none hover: hover:scale-125"
          type="submit"
        >
          {" "}
          Proceed
        </button>
      </form>
      <div>
        {allLogin.map((curElem) => {
          return (
            <div>
              <p>{curElem.email}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmailVerification;
