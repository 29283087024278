import React from "react";
import { MdSecurity } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";
function Footer() {
  return (
    <div className="max-w-[884px] w-full flex justify-between text-lg px-4 py-2 rounded-b-3xl   text-white bg-[#39ACE3] pb-0 pt-0  mt-0">
      <p className="flex flex-col items-center gap-3 text-center">
        {" "}
        <RiSecurePaymentLine className="text-4xl" /> 100% secure Transaction
      </p>
      <p className="flex flex-col items-center gap-3 text-center">
        {" "}
        <MdSecurity className="text-4xl" /> Your Data is highly secure
      </p>
      <p className="flex flex-col items-center gap-3 text-center">
        {" "}
        <VscWorkspaceTrusted className="text-4xl" /> User Trusted Product
      </p>
    </div>
  );
}

export default Footer;
