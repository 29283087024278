import React from "react";
import axios from "axios";
import Axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useGlobalProgressContext } from "./ProgressContext";
const PaymentInfo = () => {
  const { coupon } = useGlobalProgressContext();
  const url = "https://api.ecell.org.in/api/payment/";
  const local_email = localStorage.getItem("icamp_email_step_1");
  const split = local_email.split("@")[0];
  const [contact, setContact] = useState();
  const [payment, setPayment] = useState(true);
  const [name, setName] = useState("");
  const [Branch, setBranch] = useState();
  const handleClick = async (e) => {
    e.preventDefault();
    const local_email = localStorage.getItem("icamp_email_step_1");
    console.log(local_email);
    // if (payment === false) {
    axios
      .post(url, {
        email: local_email,
      })
      .then((res) => {
        console.log(res);
        window.location.replace(res.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   toast.error("Payment Already made", {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  useEffect(() => {
    setTimeout(() => {
      const local_email = localStorage.getItem("icamp_email_step_1");
      Axios.get(`https://api.ecell.org.in/api/checkmail/:${local_email}`).then(
        (res) => {
          if (res.data.status === "User already exist") {
            setName(res.data.user.name);
            setBranch(res.data.user.branch);
            setContact(res.data.user.no);
            setPayment(res.data.user.payments.isPaid);
          }
        }
      );
    }, 1000);
  }, []);
  return (
    <div>
      <div className="flex flex-col items-center w-full gap-10 pt-0">
        <h1 className="mb-0 text-3xl font-bold">
          Your Payment{" "}
          <span className="underline underline-offset-2 decoration-4 decoration-[#39ACE3]">
            {" "}
            Details{" "}
          </span>
        </h1>
        <div className="flex flex-col items-end gap-4">
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="name">
              Full Name
            </label>
            <p className="px-2 w-[200px] py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0">
              {name}
            </p>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="roll">
              Roll No
            </label>
            <p className="px-2 w-[200px] py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0">
              {split}{" "}
            </p>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="contact">
              Phone No
            </label>
            <p className="px-2 w-[200px] py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0">
              {contact}
            </p>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-4">
            <label className="font-semibold " htmlFor="contact">
              Branch
            </label>
            <p className="px-2 w-[200px] py-1  bg-[#E8F0FE] font-semibold  rounded-md  outline-none focus:ring-2 focus:ring-[#39ACE3] border-0">
              {Branch}
            </p>
          </div>

          <div className="flex justify-center w-full my-16">
            {coupon === "CAREER20KE" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 399{" "}
              </p>
            )}
            {coupon === "SPECIAL10" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 449{" "}
              </p>
            )}
            {coupon === "PRONOOB15" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 425{" "}
              </p>
            )}
            {coupon === "AEWS20" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 399{" "}
              </p>
            )}
            {coupon === "KASH20" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 399{" "}
              </p>
            )}
            {coupon === "KEYE20" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 399{" "}
              </p>
            )}
            {coupon === "FRESHERS15" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 425{" "}
              </p>
            )}
            {coupon === "TREAT15" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 425{" "}
              </p>
            )}
            {coupon === "MSAC20" && (
              <p className="text-3xl font-semibold ">
                {" "}
                To Be Paid : INR <s>499</s> 399{" "}
              </p>
            )}
            {!coupon && (
              <p className="text-3xl font-semibold "> To Be Paid : INR 499 </p>
            )}
          </div>
        </div>

        <button
          className="px-4 py-2 mb-4 ml-20 transition duration-300 bg-[#39ACE3] text-white font-semibold rounded-md shadow-md focus:outline-none hover: hover:scale-125"
          type="submit"
          onClick={handleClick}
        >
          {" "}
          Add to the waiting list
        </button>
      </div>
    </div>
  );
};

export default PaymentInfo;
