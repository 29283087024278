import React, { useState, useContext, useEffect } from "react";

const ProgressContext = React.createContext();

const ProgressProvider = ({ children }) => {
  const [emailDone, setEmailDone] = useState(false);
  const [detailsDone, setDetailsDone] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [paid, setPaid] = useState(false);
  const [coupon, setCoupon] = useState("");
  return (
    <ProgressContext.Provider
      value={{
        emailDone,
        setEmailDone,
        detailsDone,
        setDetailsDone,
        paymentDone,
        setPaymentDone,
        paid,
        setPaid,
        coupon,
        setCoupon,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useGlobalProgressContext = () => {
  return useContext(ProgressContext);
};

export { ProgressContext, ProgressProvider };
